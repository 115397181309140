<template>
 <!-- <body> -->

     <div>
        <!-- home-header started -->
        
        <div class="wrapper">
            <headerview/>
            <div class="body-section">
                <SideBar/>
                <div class="body-content">
                    <router-view/>
                </div>
                 
                  <!--  Profile image and logout buttons--->
                <!-- <div class="body_content">                
                    <div class="main_content">                    
                       
                    </div>
                    <footerView ></footerView>
                   
                </div> -->
            </div>
        </div>
        
             
     </div>
</template>

<script>

import headerview from "@/components/header.vue"
import SideBar from '../components/sideBar.vue'

//import sideBar from "@/components/sideBar.vue"
//import footerView from "@/components/footer.vue"
 export default {
  name: 'layout',
  components: {
    headerview,
    SideBar,
   // sideBar,
  //  footerView
  },
  data(){
     
      return {
      }
  },
  mounted(){
      //console.log(JSON.stringify(this.$router.currentRoute._rawValue.meta));
  }
}

</script>